<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Attributes</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Attributes
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('attribute', 'create')"
                      @click="manageAttributeDialog({}, 'open')"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Attributes
                  </v-btn>

                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.title"
                      v-on:keyup.enter="__search"
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                      :items="types"
                      v-model="search.type"
                      v-on:keyup.enter="__search"
                      @input="search.type = $event !== null ? $event : ''"
                      label="Type"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>


                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="__search"
                      @input="search.is_active = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="value"
                      :items="status"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      @click.prevent="__search"
                      class="mt-1 btn btn-primary"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')"><strong>Name</strong></th>
                  <th><strong>Type</strong></th>
                  <th><strong>Icon</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="attribute in sortedItems" :key="attribute.id">
                    <td>
                      <a @click="__edit(attribute.id)"
                         class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ attribute.title }}
                      </a>
                    </td>
                    <td class="text-left">
                      {{ attribute.type ? attribute.type : '--' }}
                    </td>
                    <td>
                      {{ attribute.icon }}
                    </td>
                    <td>
                      <span class="badge badge-success text-lg`"
                            v-bind:class="{ 'badge-success': attribute.is_active, 'badge-danger': !attribute.is_active }"
                      >{{ attribute.is_active ? 'Active' : 'In-active' }}</span>
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item"
                                             v-if="checkIsAccessible('attribute', 'edit')"
                            >
                              <a @click="manageAttributeDialog(attribute, 'open')" class="navi-link">
                                                                <span class="navi-icon">
                                                                    <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"
                                             v-if="checkIsAccessible('attribute', 'delete')"
                            >
                              <a class="navi-link" @click.prevent="__delete(attribute.id)">
                                                            <span class="navi-icon">
                                                                <v-icon color="red darken-2">fas fa-trash</v-icon>
                                                            </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0">
                    <td colspan="5" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="__getAll"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update :dialog="attribute_dialog" :attribute="attribute"
                       @refresh="manageAttributeDialog({}, 'close')"></create-and-update>
  </v-app>
</template>

<script>
import AttributeService from "@/services/academic/attribute/AttributeService";
import CreateAndUpdate from "./CreateAndUpdate";

const attributeService = new AttributeService();

export default {
  name: "Attributes-Index",
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      loading: false,
      attribute_dialog: false,
      menu: false,
      attributes: [],
      types: ['Institution', 'Program'],
      total: null,
      perPage: null,
      page: null,
      attribute: {
        title: null,
        icon: null,
        type: null,
        description: null,
        is_active: 1,
      },
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search: {
        title: '',
        type: '',
        is_active: '',
      }
    }
  },
  mounted() {
    this.__getAll();

  },
  computed: {
    sortedItems() {
      const list = this.attributes.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    resetAttribute() {
      this.attribute = {
        title: null,
        icon: null,
        type: null,
        description: null,
        is_active: 1,
      }
    },
    manageAttributeDialog(item = {}, type = 'open') {
      if (type == 'open') {
        if (item.id) {
          this.attribute = item;
        } else {
          this.resetAttribute();
        }
        this.attribute_dialog = true
      } else {
        this.attribute_dialog = false
        this.resetAttribute();
        this.__getAll()
      }
    },

    // update
    __edit(attribute_id) {
      this.$router.push({
        name: 'attributes-edit',
        params: {attribute_id: attribute_id}
      });
    },

    // delete
    __delete(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            attributeService
                .delete(id)
                .then((response) => {
                  this.__getAll()
                  this.$snotify.success("Attribute Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    // fetch
    __getAll() {
      this.loading = true;
      attributeService
          .paginate(this.search, this.page)
          .then(response => {
            this.attributes = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    // search
    __search() {
      this.__getAll();
    },

    // categories

    // CRUD METHODS
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    // router and redirection


  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>>