<template>
  <v-dialog max-width="800" persistent v-model="dialog">
    <v-card>
      <v-card-title>
        {{ title }} Attribute
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field :error="$v.attribute.title.$error" dense
                          label="Attributes Name"
                          outlined
                          v-model="attribute.title">
              <template v-slot:label>
                Attributes Name <span class="text-danger">*</span>
              </template>
            </v-text-field>
            <span class="text-danger" v-if="$v.attribute.title.$error">Name is required</span>
          </v-col>


          <v-col md="12">
            <v-select
                :error="$v.attribute.type.$error"
                :items="['Institution','Program']"
                v-model="attribute.type"
                dense
                flat
                label="Type"
                outlined
            >
            </v-select>
            <span class="text-danger" v-if="$v.attribute.type.$error">Attributes Type is required</span>
          </v-col>


          <v-col md="12">
            <v-text-field dense label="Icon"
                          outlined
                          :error="$v.attribute.icon.$error"
                          v-model="attribute.icon"></v-text-field>
            <span class="text-danger" v-if="$v.attribute.icon.$error">Attributes Icon is required</span>
          </v-col>


          <v-col cols="12">
            <label for>Description</label>
            <ckeditor :config="editorConfig"
                      v-model="attribute.description"></ckeditor>
            <span class="text-danger" v-if="$v.attribute.description.$error">Attributes description is required</span>

          </v-col>
          <v-col cols="12" md="6">
            Status
            <v-switch
                :label="attribute.is_active ? 'Active' : 'Inactive'"
                v-model="attribute.is_active"
            ></v-switch>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            @click="resetForm"
            color="mr-2" text dark
            medium
        >Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            @click="createOrUpdate"
            class="btn btn-primary"
            medium
            v-if="checkIsAccessible('attribute', 'create') || checkIsAccessible('attribute', 'edit')"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {email, required, url} from "vuelidate/lib/validators";
import AttributeService from "@/services/academic/attribute/AttributeService";

const attributeService = new AttributeService();
export default {
  name: "CreateAndUpdate",
  components: {},
  props: ['dialog', "attribute"],
  data() {
    return {
      title: 'Create',
      menu: false,
      loading: false,
      logoSelector: false,
      activePicker: null,
      date: null,
      select: null,
      attribute_type: [
        {
          id: 1,
          name: "Graduate",

        },
        {
          id: 2,
          name: "Under Graduate",

        },
        {
          id: 3,
          name: "Post Graduate",

        },
        {
          id: 4,
          name: "Diploma",

        },
      ],
      attribute_id: null,
      errors: [],
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      }
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  validations: {
    attribute: {
      title: {required},
      icon: {required},
      type: {required},
      description: {required},
      is_active: {required}
    }
  },
  mounted() {
    this.attribute_id = this.$route.params.attribute_id;
    this.__detail();
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },


    convertToFormData() {
      let formData = new FormData();
      for (let key in this.attribute) {
        if (key === "file" && this.attribute[key] != null && this.attribute[key] != undefined) {
          formData.append('file', this.attribute[key]);
        } else {
          if (this.attribute[key]) {
            formData.append(key, this.attribute[key]);
          }
        }
      }
      return formData;
    },
    changeImage(e) {
      this.img = URL.createObjectURL(this.logo_img)
    },
    __detail() {
      if (this.attribute_id != null || this.attribute_id != undefined) {
        attributeService
            .show(this.attribute_id)
            .then(response => {
              this.attribute = response.data.attribute;
              if (this.attribute.image_path != null) {
                this.imgurl = this.attribute.image_path.thumb;
              }
            }).catch(err => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },
    previewImage() {
      if (this.attribute.file != null) {
        this.imgurl = URL.createObjectURL(this.attribute.file)
      } else {
        this.imgurl = null;
      }
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (this.attribute.id) this.update();
        else this._create();
      }
    },
    cancel() {
      this.$tabs.close();
    },
    _create: function () {
      attributeService
          .create(this.attribute)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Attribute created successfully");
            this.resetForm();
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      attributeService
          .update(this.attribute.id, this.attribute)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Attribute updated successfully");
            this.resetForm();
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Attribute something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm() {
      this.$v.$reset();
      this.$emit("refresh")

    },
  }
}
</script>
<style>
@import 'https://emoji-css.afeld.me/emoji.css';
</style>
